@import "@design";

.switch-account--wrap {
  margin: 10px 0;
  color: $gray-light;
}

.switch-accounts-btn {
  margin: 0 5px;
  color: $gray-light;
  cursor: pointer;
  transition: color 0.3s ease;
  &:hover,
  &:active,
  &:focus {
    color: $blue;
    text-transform: underline;
  }
}
